import { faGear, faHandshake, faUserGraduate } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function getSettingIcon(
    area: string
) {
    let iconComponent;
    switch (area) {
        case 'participants':
            iconComponent = (
                <FontAwesomeIcon
                    width={15}
                    className="me-2"
                    style={{ color: 'var(--bs-gray-600)' }}
                    icon={faUserGraduate}
                />
            );
            break;
        case 'deals':
            iconComponent = (
                <FontAwesomeIcon
                    width={15}
                    className="me-2"
                    style={{ color: 'var(--bs-gray-600)' }}
                    icon={faHandshake}
                />
            );
            break;
        case 'settings':
            iconComponent = (
                <FontAwesomeIcon
                    width={15}
                    className="me-2"
                    style={{ color: 'var(--bs-gray-600)' }}
                    icon={faGear}
                />
            );
            break;
        default:
            break
    }
    return iconComponent;
}