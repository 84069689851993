import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEnumValue } from '../utils/enum';
import { FieldConfig } from '../interfaces';
import { getFieldConfigByResourceName } from '../utils/utils';
import { bgsStatusColorMap, bgsStatusIconMap } from './filter/iconAndColorMappings';

interface BgsStatusIndicatorProps {
    bgsStatus: number;
    fieldConfigs?: FieldConfig[];
}

/**
 * BgsStatusIndicator Component
 * 
 * This component is responsible for displaying a visual status indicator based on 
 * the provided `bgsStatus`. The indicator includes an icon and a label, with the color 
 * and icon determined by mappings (`bgsStatusIconMap`, `bgsStatusColorMap`).
 * 
 * Props:
 * - `bgsStatus` (number): The status code used to determine the icon, color, and label.
 * - `fieldConfigs` (optional, FieldConfig[]): Array of field configurations used to get 
 *   the status options and label.
 */

const BgsStatusIndicator: React.FC<BgsStatusIndicatorProps> = ({ bgsStatus, fieldConfigs }) => {
    const bgsStatusIcon = bgsStatusIconMap[Number(bgsStatus)];
    const bgsStatusColor = bgsStatusColorMap[Number(bgsStatus)];
    const bgsStatusFieldConfig = fieldConfigs
        ? getFieldConfigByResourceName(fieldConfigs, 'lastDeal.bgsStatus')
        : undefined;

    return (
        <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 ${bgsStatusColor}`}>
            <FontAwesomeIcon width={15} icon={bgsStatusIcon} className="me-2" />
            <span>
                {getEnumValue(bgsStatusFieldConfig?.options ?? {}, bgsStatus.toString()) || 'Status unbekannt'}
            </span>
        </div>
    );
};

export default BgsStatusIndicator;
