import React, { FormEvent, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Spinner,
} from 'react-bootstrap';
import { MediaItem } from '../../../interfaces';
import { ApiClient } from '../../../services/ApiClient';
import { handleInputChange } from '../../../utils/form/utils';

type MediumEditModalProps = {
  modalTitle: string;
  media: MediaItem;
  onSubmit: (message: string, isError: boolean) => void;
  onModalClose: () => void;
};

interface FormValues {
  title: string;
  description: string
}

/**
 * MediumEditModal Component
 *
 * This component provides a modal dialog for editing the details of a media item.
 * It includes a form with fields for updating the title and description, 
 * along with validation and submission functionality.
 *
 * Props:
 * - `modalTitle`: A string to display as the modal's title.
 * - `media`: An object representing the media item to edit. Includes properties such as `id`, `title`, `description`, `mediaType`, and more.
 * - `onSubmit`: A callback function invoked upon successful or failed submission. Receives a message and an error flag.
 * - `onModalClose`: A callback function invoked when the modal is closed.
 */

const MediumEditModal: React.FC<MediumEditModalProps> = ({
  modalTitle,
  media,
  onSubmit,
  onModalClose
}) => {
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(
    {
      id: media.id,
      description: media.description || '',
      extension: media.extension || '',
      folder_id: media.folder_id ?? 0,
      hash: media.hash || '',
      month: media.month || '',
      name: media.name || '',
      origin: media.origin || '',
      mediaType: media.mediaType || '',
      size: media.size || 0,
      title: media.title || '',
      mimeType: media.mimeType || '',
      year: media.year || '',
    }
  );

  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false,
    disabled = false
  ) => (
    <Form.Group className="mb-3 w-100" controlId={id}>
      {label && <Form.Label>{label}</Form.Label>}

      {type === 'textarea' ? (
        <Form.Control
          as="textarea"
          rows={5}
          type={type}
          value={formValues[id]}
          onChange={(e) => handleInputChange(e, setFormValues)}
          required={required}
          className='text-black bg-grey'
          disabled={disabled}
        />
      ) : (
        <Form.Control
          type={type}
          value={type !== 'file' ? formValues[id] : ''}
          onChange={(e) => handleInputChange(e, setFormValues)}
          required={required}
          className='text-black bg-grey'
          disabled={disabled}
        />
      )}

      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </Form.Group>
  );

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        await ApiClient.put(`/media/${formValues.id}`, formValues)
        onSubmit('Erfolgreich gespeichert', false)
      } catch (error) {
        onSubmit('Fehler beim Speichern', true)
      } finally {
        setIsLoading(false);
        onModalClose();
        setValidated(false);
      }
    }
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        show
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
          </div>
        </Modal.Header>

        <Form
          className='text-black'
          noValidate
          validated={validated}
          onSubmit={submit}
        >
          <Modal.Body>
            <Row>
              <Col>
                {createFormGroup('title', 'Name', 'text', true, formValues.mediaType !== 'inode' || media.locked ? true : false)}
                {createFormGroup('description', 'Beschreibung', 'textarea', false, media.locked)}
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button className="btn-soft-primary" variant="secondary" onClick={onModalClose}>
              { media.locked ? 'Schließen' : 'Abbrechen' }
            </Button>
            { !media.locked && 
              <Button
                type="submit"
                variant="primary"
                disabled={isLoading}
              >
                Speichern
                {isLoading && (
                  <Spinner
                    className="ms-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                )}
              </Button>
            }
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default MediumEditModal;
