import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { AircallNumber, Setting } from '../../interfaces';
import Card from '../bootstrap/card';

import { usePermissions } from '../../hooks/usePermissions';
import { PermissionsEnum } from '../../utils/enum';
import SkeletonCard from '../skeleton/SkeletonCard';
import GenericInfosCard from '../GenericInfosCard';
import AircallNumbersModal from './modal/AircallNumbersModal';

interface AircallNumbersDetailsProps {
    isLoading?: boolean;
    setting?: Setting | null
    onSubmitSuccess: (settingsId: number) => void;
}

/**
 * AircallNumbersDetails Component
 *
 * This component displays details of Aircall settings, including general information 
 * and a list of associated Aircall numbers. It allows editing settings through a modal.
 *
 * Props:
 * - `isLoading?`: Optional boolean indicating whether the component is in a loading state.
 * - `setting?`: Optional `Setting` object containing the Aircall settings data.
 * - `onSubmitSuccess`: Function invoked when settings are successfully updated, receiving the updated settings ID.
 */
const AircallNumbersDetails: React.FC<AircallNumbersDetailsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const aircallNumbers = setting?.details as AircallNumber[]

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
        return info1;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewSettings, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Allgemein"
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    <Card className={`card-block card-stretch card-height`}>
                        <Card.Body>
                            <h5>Aircall Nummern</h5>
                            <ul className="list-no-dots">
                                {aircallNumbers?.length > 0 ? (
                                    aircallNumbers?.map((number, index) => (
                                        <li key={index} className='p-1'>
                                            {number.name}: <span className='text-black'>{number.number}</span>
                                        </li>
                                    ))
                                ) : (
                                    <li>Keine Aircall Nummern vorhanden</li>
                                )}
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {setting && showAddEditModal && <AircallNumbersModal
                modalTitle={`${setting.title} bearbeiten`}
                setting={setting}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            >
            </AircallNumbersModal>
            }
        </>
    );
};

export default AircallNumbersDetails;
