import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, ReactNode } from 'react';
import { Card, Button, Collapse } from 'react-bootstrap';

interface CollapsibleCardProps {
    title: string;
    headerRightElement?: ReactNode;
    children?: ReactNode;
    titleInfo?: number | string;
    subTitle?: ReactNode | string
    showDivider?: boolean;
    initialOpen?: boolean;
    disabled?: boolean;
    customCardCssClass?: string
    showExpandCollapseIcon?: boolean
}

/**
 * CollapsibleCard Component
 * 
 * This component renders a card with a header that can be clicked to expand or collapse the content.
 * The initial state of the card (whether it's open or closed) can be controlled via the `initialOpen` prop.
 * It also supports disabling interaction via the `disabled` prop, which will make the title and button inactive.
 * 
 * Props:
 * - `title` (string): The title displayed on the card header.
 * - `headerRightElement` (ReactNode): Optional element rendered on the right side of the header (e.g., a button or icon).
 * - `children` (ReactNode): The content to be displayed inside the collapsible area.
 * - `titleInfo` (number | string): Optional additional information displayed next to the title.
 * - `subTitle` (ReactNode | string): Optional subtitle displayed below the title, useful for supplementary information.
 * - `showDivider` (boolean): Adds a horizontal divider inside the card when the content is shown.
 * - `initialOpen` (boolean): Controls whether the card should start in an open state (default: false).
 * - `disabled` (boolean): Disables the card toggle functionality and applies disabled styling (default: false).
 * 
 * Example Usage:
 * 
 * <CollapsibleCard
 *   title="Deals"
 *   titleInfo={5}
 *   subTitle="Active deals for the month"
 *   initialOpen={true}
 *   disabled={false}
 * >
 *   <p>This is some collapsible content.</p>
 * </CollapsibleCard>
 */
const CollapsibleCard: React.FC<CollapsibleCardProps> = ({
    title,
    titleInfo,
    subTitle,
    headerRightElement,
    showDivider,
    children,
    initialOpen = false,
    disabled = false,
    customCardCssClass,
    showExpandCollapseIcon = true
}) => {
    const [open, setOpen] = useState(initialOpen);

    useEffect(() => {
        if (!disabled) {
            setOpen(initialOpen);
        }
    }, [initialOpen, disabled]);

    const toggleOpen = () => {
        if (!disabled) {
            setOpen(!open);
        }
    };

    return (
        <Card className={`${customCardCssClass}`}>
            <Card.Header className='p-3'>
                <div className="d-flex justify-content-between align-items-center">
                    <div
                        onClick={disabled ? undefined : toggleOpen}
                        className={`d-flex justify-content-center align-items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                    >
                        {showExpandCollapseIcon && <Button
                            variant="link"
                            className={`ps-2 pe-4`}
                            disabled={disabled}
                        >
                            {open ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                        </Button>}

                        <div className='d-flex flex-column justify-content-center'>
                            <h4 className={`mb-0 ${disabled ? 'text-muted' : ''}`}>
                                {title} {titleInfo?.toString() && <span className='text-muted'>{titleInfo}</span>}
                            </h4>
                            {subTitle && <p className='m-0'>{subTitle}</p>}
                        </div>

                    </div>
                    {headerRightElement && (
                        <div>
                            {headerRightElement}
                        </div>
                    )}
                </div>
            </Card.Header>
            <Collapse in={open}>
                <div>
                    {showDivider && <div className='px-3'><div className="horizontal-line"></div></div>}
                    {open && children}
                </div>
            </Collapse>
        </Card>
    );
};

export default CollapsibleCard;
