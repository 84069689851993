import { Permissions } from '../interfaces';

export const CertificationStatusEnum = {
  0: 'Benötigt Bearbeitung',
  1: 'Benötigt Zertifizierung',
  2: 'In Zertifizierung',
  3: 'Zertifiziert',
};

export type CertificationStatus = keyof typeof CertificationStatusEnum;

export const PublicationStatusEnum = {
  1: 'Veröffentlicht',
  2: 'Unveröffentlichte Änderungen',
  0: 'Unveröffentlicht',
  3: 'Archiviert'
};

export type PublicationStatus = keyof typeof PublicationStatusEnum;

export const AttendanceStatusEnum = {
  0: 'Prüfung erforderlich',
  1: 'Alles in Ordnung',
};

export type AttendanceStatus = keyof typeof AttendanceStatusEnum;

export const YesNoMapping = {
  true: 'Ja',
  false: 'Nein',
};

export const ActiveInactiveMapping = {
  0: 'Inaktiv',
  1: 'Aktiv',
};

export enum PermissionsEnum {
  Dashboard = 'dashboard',
  ViewDeals = 'deals',
  ViewParticipants = 'participants',
  ViewProducts = 'products',
  ViewMedia = 'media',
  ViewSettings = 'settings',
  ViewUsers = 'users',
  ViewRoles = 'roles',
  ViewWebhooks = 'webhooks',
  ViewApiKeys = 'apikeys',
  ViewSatisfactions = 'satisfactions',
  ViewActivities = 'activities',
  ViewAttendances = 'attendances',
}

export const CompanyEnum = {
  oc: 'OneCareer',
  cn: 'ChapterNext',
};

export type Company = keyof typeof CompanyEnum;

export const MediaPlaceholderImages = {
  pdf: 'https://storage.onecareer.de/placeholder-media-format-pdf.webp',
  word: 'https://storage.onecareer.de/placeholder-media-format-word.webp',
  excel: 'https://storage.onecareer.de/placeholder-media-format-excel.webp',
  music: 'https://storage.onecareer.de/placeholder-media-format-music.webp',
  video: 'https://storage.onecareer.de/placeholder-media-format-video.webp',
};

export enum SettingType {
  nameConventionDeals = 'nameConventionDeals', // Namenskonvention, Dateiupload Dealdateien
  listOfAircallNumbers = 'listOfAircallNumbers' // AirCall-Telefonummern
}

export const CertifierEnum = {
  acadCert: 'acadCert',
};

export const RoleStatusEnum = {
  0: 'Inaktiv',
  1: 'Aktiv',
};

export type RoleStatus = keyof typeof RoleStatusEnum;

export const moduleTranslations: Record<keyof Permissions, string> = {
  settings: 'Einstellungen',
  users: 'Nutzerverwaltung',
  roles: 'Rollenverwaltung',
  dashboard: 'Dashboard',
  companies: 'Unternehmen',
  deals: 'Deals',
  participants: 'Teilnehmer',
  products: 'Produkte',
  webhooks: 'Webhooks',
  apikeys: 'API-Schlüssel',
  media: 'Medien',
  satisfactions: 'Zufriedenheiten',
  activities: 'Aktivitäten',
  attendances: 'Anwesenheiten'
};

export const DealStatusEnum = {
  0: 'Startet bald',
  1: 'Lernend',
  2: 'Abgeschlossen',
  3: 'Abgebrochen',
};

export type DealStatus = keyof typeof DealStatusEnum;

export const TimeModelEnum = {
  fulltime: 'Vollzeit',
  parttime: 'Teilzeit',
};

export type TimeModel = keyof typeof TimeModelEnum;

export const SelfPay = {
  0: 'Kein Selbstzahler',
  1: 'Selbstzahler',
};

export const PSM = {
  0: 'Keine PSM Prüfung',
  1: 'PSM wird vom Bildungsträger übernommen',
};

export const AbortReasonOptions = {
  workFound: 'Neuen Job gefunden',
  workFoundNotParttime: 'Neuen Job gefunden - Nicht berufsbegleitend',
  rival: 'Für einen anderen Träger entschieden',
  lostValidity: 'BGS hat Gültigkeit verloren',
  lost: 'BGS verloren / abhanden gekommen',
  cancellation: 'Stornierung',
  terminationbyProvider: 'Beendigung seitens Anbieter',
  notStarted: 'Nicht gestartet / Deal geplatzt',
  notKnown: 'Grund nicht bekannt',
};

export const ProductTypesEnum = {
  educations: 'Weiterbildungen',
  courses: 'Kurse',
  combinations: 'Kombinationen',
  modules: 'Module',
  learningunits: 'Lerneinheiten',
};

export type ProductTypes = keyof typeof ProductTypesEnum;

export const ProcessOptions1 = {
  '-': 'Keinen Prozess initialisieren!',
  allAudit: 'Alle Audit-Dokumente generieren',
  createDateData: 'Zeit-Datensatz erstellen',
  measureAttachment: 'Anlage des Maßnahmenträgers generieren',
  presences: 'Anwesenheitsliste/n generieren',
  presencesGaps: 'Anwesenheitsliste/n auffüllen (Spezial)',
  releases: 'Modulliste neu generieren',
  satisfaction: 'Kundenbewertung/en generieren',
  studyplan: 'Lehrplan / Lehrpläne generieren',
  unlockEducation: 'Lernwelt freischalten',
};

export const ProcessOptions2 = {
  newLw1Create: '(1) Teilnehmer Freischalten/aktivieren',
  newLw2Modules: '(2) Alle verfügbaren Kurs/Module aktivieren',
};

export const WebhookModuleEnum = {
  'participants': 'Teilnehmer',
};

export const SatisfactionQuestionsEnum = {
  "virtual-room": "Wie bewertest Du Deine Erfahrung im virtuellen Raum während der Weiterbildung?",
  "professional-knowledge": "Wie bewertest Du die fachliche Kenntnis Deines Dozenten?",
  "pedagogical-competence": "Wie bewertest Du die pädagogischen Kompetenzen Deines Dozenten?",
  "organisation": "Wie bewertest Du die Organisation und den Ablauf der Weiterbildung?",
  "learning-environment": "Wie bewertest Du die Lernumgebung?",
  "benefit-of-training": "Wie bewertest Du den Nutzen der Weiterbildung für Deine Zukunft?"
};

export type SatisfactionQuestions = keyof typeof SatisfactionQuestionsEnum;

export const SettingAreaEnum = {
  'participants': 'Teilnehmer',
  'settings': 'Einstellungen',
  'deals': 'Deals'
};

export type SettingArea = keyof typeof SettingAreaEnum;

export enum EmailTemplateType {
  GENERAL = 0,
  BGS_TO_AFA = 1,
  WV_TO_AFA = 2,
  WV_TO_TEILNEHMER = 3,
}

export function getEnumValue<T extends Record<string, any>>(
  enumObj: T,
  key: string | boolean | null | undefined
): string {
  if (key === null || key === undefined) {
    return '-';
  }

  const normalizedKey = String(key);

  return normalizedKey in enumObj ? String(enumObj[normalizedKey]) : normalizedKey;
}
