import React, { FormEvent, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
    Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { NameConventionDeals, Setting } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import Card from '../../bootstrap/card';
import { handleInputChange } from '../../../utils/form/utils';
import FormGroup from '../../form/FormGroup';

type NameConventionDealsModalProps = {
    modalTitle: string;
    setting?: Setting;
    onSubmitSuccess: (settingsId: number) => void;
    onModalClose: () => void;
};

interface FormValues {
    title: string;
    description: string;
    ident: string;
    wv: string
    bgs: string
    offer: string
}

/**
 * NameConventionDealsModal Component
 *
 * This component provides a modal interface for editing naming conventions for deals. 
 * It allows users to define or update naming guidelines for contracts, vouchers, and offers, 
 * and provides a list of available placeholders for use in these guidelines.
 *
 * Props:
 * - `modalTitle`: A string for the modal's title.
 * - `setting?`: Optional `Setting` object containing the current naming conventions and related settings.
 * - `onSubmitSuccess`: Function invoked after a successful update, receiving the updated settings ID.
 * - `onModalClose`: Function invoked to close the modal.
 */
const NameConventionDealsModal: React.FC<NameConventionDealsModalProps> = ({
    modalTitle,
    setting,
    onSubmitSuccess,
    onModalClose
}) => {
    const fileNames = setting?.details as NameConventionDeals

    const { show, message, error, showToast, hideToast } = useToast();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const [formValues, setFormValues] = useState({
        title: setting?.title ?? '',
        description: setting?.description ?? '',
        ident: setting?.ident ?? '',
        wv: fileNames?.wv,
        bgs: fileNames?.bgs,
        offer: fileNames?.offer,
    });
    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                const response = await ApiClient.put(
                    `/settings/${setting?.id}`,
                    prepareFormDataForSubmit(formValues)
                );
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    const prepareFormDataForSubmit = (formValues: FormValues) => {
        return {
            title: formValues.title,
            ident: formValues.ident,
            description: formValues.description,
            details: {
                wv: formValues.wv,
                bgs: formValues.bgs,
                offer: formValues.offer,
            }
        };
    }

    return (
        <>
            <Modal
                centered
                show
                onHide={onModalClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            {' '}
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button p-0"
                            >
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <>

                            <Row>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            <div className='mb-3'>
                                                <FormGroup<FormValues>
                                                    id="wv"
                                                    label='Benennungsrichtlinie für Weiterbildungsverträge'
                                                    value={formValues.wv}
                                                    onChange={(e) => handleInputChange(e, setFormValues)}
                                                    marginBottom='1'
                                                />
                                                <small >
                                                    Bitte das Feld leer lassen, wenn keine Richtlinie angewendet werden soll!
                                                </small>
                                            </div>

                                            <div>
                                                <FormGroup<FormValues>
                                                    id="bgs"
                                                    label='Benennungsrichtlinie für Bildungsgutscheine'
                                                    value={formValues.bgs}
                                                    onChange={(e) => handleInputChange(e, setFormValues)}
                                                    marginBottom='1'
                                                />
                                                <small>
                                                    Bitte das Feld leer lassen, wenn keine Richtlinie angewendet werden soll!
                                                </small>
                                            </div>

                                            <div>
                                                <FormGroup<FormValues>
                                                    id="offer"
                                                    label='Benennungsrichtlinie für Angebote'
                                                    value={formValues.offer}
                                                    onChange={(e) => handleInputChange(e, setFormValues)}
                                                    marginBottom='1'
                                                />
                                                <small>
                                                    Bitte das Feld leer lassen, wenn keine Richtlinie angewendet werden soll!
                                                </small>
                                            </div>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            <div><label className="form-label mb-1 text-black">Verfügbare Platzhalter</label></div>
                                            <small className='mb-2'>Bitte verwenden Sie auch die eckigen Klammern. Der Platzhalter wird durch den entsprechenden Wert ersetzt.</small>
                                            <Table borderless size="sm" className='m-0 p-0 text-black'>
                                                <tbody>
                                                    <tr>
                                                        <td className="p-0">[companyShortcut]</td>
                                                        <td className="p-2"><FontAwesomeIcon icon={faCaretRight} /></td>
                                                        <td className="p-0">Firmenkürzel</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-0">[date]</td>
                                                        <td className="p-2"><FontAwesomeIcon icon={faCaretRight} /></td>
                                                        <td className="p-0">Aktuelles Datum im Format YYYY-MM-DD</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="p-0">[bgsNumber]</td>
                                                        <td className="p-2"><FontAwesomeIcon icon={faCaretRight} /></td>
                                                        <td className="p-0">BGS-Nummer</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-0">[sourceTitle]</td>
                                                        <td className="p-2"><FontAwesomeIcon icon={faCaretRight} /></td>
                                                        <td className="p-0">Ursprünglicher Titel der Datei</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default NameConventionDealsModal;
