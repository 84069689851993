import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import { EventInput } from '@fullcalendar/core';
import { Participant } from '../../../interfaces';
import EngagementsList from './EngagementsList';

type EngagementsProps = {
  participant: Participant;
  calendarRef: React.RefObject<FullCalendar>;
  viewMode: 'calendar' | 'list';
};

const events: EventInput[] = [
  {
    start: '2025-01-10',
    end: '2025-01-10',
    display: 'background',
    color: '#C03221',
  },
  {
    start: '2025-01-11',
    end: '2025-01-11',
    display: 'background',
    color: '#C03221',
  },
  {
    start: '2025-01-12',
    end: '2025-01-12',
    display: 'background',
  },
  {
    start: '2025-01-13',
    end: '2025-01-13',
    display: 'background',
  },
];

const customWeekNumber = (arg: { num: number }) => {
  const weekNumber = arg.num < 10 ? `0${arg.num}` : arg.num;
  return (
    <div
      className="custom-week-number text-black"
      style={{ fontSize: '8px', opacity: 0.4 }}
    >
      <span>KW{weekNumber}</span>
    </div>
  );
};

const Engagements: React.FC<EngagementsProps> = ({
  participant,
  calendarRef,
  viewMode,
}) => {
  const [currentDate, setCurrentDate] = useState<string>('');

  const updateCurrentDate = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const date = calendarApi.getDate();
      const year = date.getFullYear();
      setCurrentDate(`${year}`);
    }
  };

  useEffect(() => {
    updateCurrentDate();
  }, []);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const handleDatesSet = () => updateCurrentDate();
      calendarApi.on('datesSet', handleDatesSet);
      return () => {
        calendarApi.off('datesSet', handleDatesSet);
      };
    }
  }, [calendarRef]);

  return (
    <div>
      {viewMode === 'calendar' ? (
        <>
          <p className="fs-5">{currentDate}</p>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, multiMonthPlugin]}
            initialView="multiMonthYear"
            events={events}
            locale="de"
            headerToolbar={false}
            firstDay={1}
            weekNumbers={true}
            weekNumberContent={customWeekNumber}
            fixedWeekCount={false}
            height="auto"
            contentHeight="auto"
          />
        </>
      ) : (
        <div>
          <EngagementsList dealId={participant.lastDeal.id} />
        </div>
      )}
    </div>
  );
};

export default Engagements;
