import React from 'react';
import { Form } from 'react-bootstrap';

/* Props for the FormGroup component */
interface FormGroupProps<T> {
    id: keyof T;
    label: string;
    type?: string;
    required?: boolean;
    disabled?: boolean;
    noResize?: boolean;
    value: T[keyof T];
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    nameError?: string | null;
    infoLabel?: string;
    marginBottom?: string
    rows?: number
    [key: string]: any;
}

/* 
 * FormGroup component for rendering labeled form controls.
 * Supports both text inputs and textareas based on the `type` prop.
 */
const FormGroup = <T,>({
    id,
    label,
    type = 'text',
    required = false,
    disabled = false,
    value,
    onChange,
    nameError,
    noResize = false,
    infoLabel = '',
    marginBottom = '3',
    rows = 5,
    ...rest
}: FormGroupProps<T>) => {
    // Function to format date strings to "YYYY-MM-DDTHH:MM" (for datetime-local)
    const formatDateTime = (dateStr: string | null) => {
        if (!dateStr) {
            return null
        }
        const [date, time] = dateStr.split('T');
        return time ? `${date}T${time.slice(0, 5)}` : date;
    };

    // Format the value only if it's a date or datetime-local
    const formattedValue =
        type === 'date' || type === 'datetime-local' ? formatDateTime(value as string) : value;

    return (
        <Form.Group className={`mb-${marginBottom} w-100 text-black`} controlId={id as string}>
            {/* Render the label and optional info label */}
            {label && (
                <Form.Label>
                    {label} {infoLabel && <small>{infoLabel}</small>}
                </Form.Label>
            )}

            {/* Conditionally render a textarea or input based on the `type` prop */}
            {type === 'textarea' ? (
                <Form.Control
                    as="textarea"
                    rows={rows}
                    value={formattedValue as string}
                    onChange={onChange}
                    required={required}
                    className={`bg-grey text-black ${noResize ? 'resize-none' : ''}`}
                    disabled={disabled}
                    {...rest}
                />
            ) : (
                <Form.Control
                    type={type}
                    value={formattedValue as string}
                    onChange={onChange}
                    required={required}
                    className={`text-black ${disabled ? 'bg-dark-grey' : 'bg-grey'}`}
                    disabled={disabled}
                    {...rest}
                />
            )}

            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>

            {/* Show an error message if `nameError` is provided and the field is 'name' */}
            {nameError && id === 'name' && (
                <div className="text-danger small">
                    {nameError}
                </div>
            )}
        </Form.Group>
    );
};

export default FormGroup;
