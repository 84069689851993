import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faFileAlt } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import SkeletonRow from "../../table/skeletonRow/SkeletonRow";
import { usePermissions } from "../../../hooks/usePermissions";
import { useSortableData } from "../../../hooks/useSortableData";
import { PermissionsEnum } from "../../../utils/enum";
import { formatDate } from "../../../utils/utils";
import { engagementStatusColorMap, engagementStatusIconMap, engagementStatusTextMap } from "../../filter/iconAndColorMappings";
import { Engagement } from "../../../interfaces";
import { ApiClient } from "../../../services/ApiClient";

interface EngagementsListProps {
  dealId: number;
}

/**
 * EngagementsList Component
 *
 * This component displays a sortable table of engagements associated with a specific deal.
 * It fetches data from an API, applies user permissions, and dynamically renders the table rows.
 * Each row includes engagement details such as the calendar week, time period, absence status,
 * overall status, and downloadable forms.
 *
 * Props:
 * - `dealId` (number): The ID of the deal for which engagements are to be displayed.
 */
const EngagementsList: React.FC<EngagementsListProps> = ({ dealId }) => {
  const navigate = useNavigate();
  const [engagements, setEngagements] = useState<Engagement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const { items: sortedEngagements, requestSort } = useSortableData(engagements);

  const fetchEngagements = async (dealId: number) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/deals/${dealId}/engagements`);
      const engagementsArray = Object.values(response.data) as Engagement[]
      setEngagements(engagementsArray);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(
        PermissionsEnum.ViewParticipants,
        "read"
      );

      if (hasPermission) {
        fetchEngagements(dealId);
      } else {
        navigate("/errors/error404");
      }
    }
  }, [permissionsLoaded]);

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
                scope="col"
                onClick={() => requestSort("week")}
              >
                Kalenderwoche
              </th>
              <th scope="col">Zeitraum</th>
              <th scope="col" onClick={() => requestSort("absences")}>
                Abwesend
              </th>
              <th scope="col">Status</th>
              <th scope="col"> <FontAwesomeIcon icon={faFileAlt} /></th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedEngagements.map((engagement) => (
                <tr key={engagement.id}>
                  <td>{`KW ${engagement.week} (${engagement.year})`}</td>
                  <td>
                    {formatDate(engagement.startOfWeek, `d.m.yy`)} -{" "}
                    {formatDate(engagement.endOfWeek, `d.m.yy`)}
                  </td>
                  <td>
                    {engagement.absences ? (
                      <span className="text-success">
                        <FontAwesomeIcon icon={faCircleCheck} /> Vorhanden
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    <span className={engagementStatusColorMap[engagement.status]}>
                      <FontAwesomeIcon icon={engagementStatusIconMap[engagement.status]} />{" "}
                      {engagementStatusTextMap[engagement.status]}
                    </span>
                  </td>
                  <td>
                    {engagement.formUrl ? (
                      <a
                        href={engagement.formUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="no-style-link"
                      >
                        <FontAwesomeIcon icon={faFileAlt} />
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && sortedEngagements.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: "50px" }}
          >
            <p className="p-0 m-0">Keine An-/Abwesenheiten gefunden</p>
          </div>
        )}
      </div>
    </>
  );
};

export default EngagementsList;
