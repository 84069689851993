import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { EventInput } from '@fullcalendar/core';
import tippy from 'tippy.js';
import { ApiClient } from '../../../services/ApiClient';
import { Agenda, Participant } from '../../../interfaces';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faDownload } from '@fortawesome/pro-regular-svg-icons';

type AgendaCalendarProps = {
  participant: Participant;
  calendarRef: React.RefObject<FullCalendar>;
};

/**
 * AgendaCalendar Component
 *
 * This component displays a monthly calendar using FullCalendar
 * to show agenda events for a specific participant. It includes:
 *
 * Props:
 * - `participant` (Participant): The participant object containing details about the current participant.
 * - `calendarRef` (React.RefObject<FullCalendar>): A reference to the FullCalendar instance for navigation and updates.
 */
const AgendaCalendar: React.FC<AgendaCalendarProps> = ({ participant, calendarRef }) => {
  const [agenda, setAgenda] = useState<EventInput[]>([]);
  const [currentDate, setCurrentDate] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchAgenda = async (dealId: number) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/deals/${dealId}/agenda`);
      const agendaResponse: Agenda[] = response.data;

      const transformedEvents: EventInput[] = agendaResponse.map((item) => ({
        title: item.unit.title,
        start: item.date.split('T')[0],
        extendedProps: {
          ues: `${item.usedUes} UE`,
        },
      }));

      setAgenda(transformedEvents);
    } catch (error) {
      console.error('Failed to fetch agenda:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCurrentDate = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const date = calendarApi.getDate();
      const month = date.toLocaleString('DE', { month: 'long' });
      const year = date.getFullYear();
      setCurrentDate(`${month} ${year}`);
    }
  };

  useEffect(() => {
    updateCurrentDate();
  }, []);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const handleDatesSet = () => updateCurrentDate();
      calendarApi.on('datesSet', handleDatesSet);
      return () => {
        calendarApi.off('datesSet', handleDatesSet);
      };
    }
  }, [calendarRef]);

  useEffect(() => {
    if (participant?.lastDeal?.id) {
      fetchAgenda(participant.lastDeal.id);
    }
  }, [participant]);

  return (
    <div className="monthly-calendar">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center 50-vw" style={{ height: '500px' }}>
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <p className="fs-5">{currentDate}</p>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={agenda}
            locale="de"
            headerToolbar={false}
            firstDay={1}
            fixedWeekCount={false}
            height="auto"
            contentHeight="auto"
            eventContent={(arg) => {
              const { title } = arg.event;
              const { ues } = arg.event.extendedProps as { ues: string };

              return (
                <div>
                  <div className="fc-event-title">{title}</div>
                  <div className='text-black fw-bold'>{ues}</div>
                </div>
              );
            }}
            eventDidMount={(info) => {
              tippy(info.el, {
                content: `${info.event.title} - ${info.event.extendedProps.ues}`,
                placement: 'top',
                theme: 'light-border',
              });
            }}
            datesSet={updateCurrentDate}
          />
        </>
      )}
    </div>
  );
};

export default AgendaCalendar;
