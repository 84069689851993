import React from 'react';
import { Form } from 'react-bootstrap';

/* Interface for select options */
interface SelectOption {
    value: string;
    label: string;
}

/* Props for the SelectGroup component */
interface SelectGroupProps<T> {
    id: keyof T;
    label: string;
    options: SelectOption[];
    value: T[keyof T];
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    required?: boolean;
    disabled?: boolean
    marginBottom?: string
}

/* 
 * SelectGroup component for rendering a labeled dropdown select.
 * Uses generic typing to ensure type safety with various form structures.
 */
const SelectGroup = <T,>({ id, label, options, value, onChange, required = false, disabled = false, marginBottom = '3' }: SelectGroupProps<T>) => {
    return (
        <Form.Group className={`mb-${marginBottom} w-100 text-black`} controlId={id as string}>
            {/* Conditionally render the label if provided */}
            {label && <Form.Label>{label}</Form.Label>}

            {/* Render the select input with options */}
            <Form.Select
                disabled={disabled}
                value={value as string}
                onChange={onChange}
                required={required}
                className="bg-grey text-black"
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );
};

export default SelectGroup;
