import React, { useState, useEffect, FormEvent } from 'react';
import { Modal, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import FormGroup from '../../form/FormGroup';
import { Template } from '../../../interfaces';
import { ApiClient } from '../../../services/ApiClient';

interface SendMailModalProps {
    onModalClose: () => void;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
    dealId: number;
    email: string;
    title: string;
    type: number;
}

interface FormValues {
    subject: string;
    message: string;
    emailAddress: string;
    selectedTemplate?: Template;
}

/**
 * SendMailModal Component
 * 
 * This component renders a modal for sending emails related to a specific deal. 
 * It includes a dynamic form with fields for recipient email address, subject, 
 * message, and optional templates that can be selected for pre-filled content.
 * 
 * Props:
 * - onModalClose: Function to handle closing the modal.
 * - onSubmitSuccess: Callback triggered after successfully sending the email.
 * - dealId: ID of the deal for which the email is being sent.
 * - email: Default recipient email address.
 * - title: Title of the modal.
 * - type: Type of the email template to be fetched.
 */

const SendMailModal: React.FC<SendMailModalProps> = ({
    onModalClose,
    onSubmitSuccess,
    dealId,
    email,
    title,
    type,
}) => {
    const [templates, setTemplates] = useState<Template[]>([]);
    const [formValues, setFormValues] = useState<FormValues>({
        subject: '',
        message: '',
        emailAddress: email ?? '',
        selectedTemplate: undefined,
    });
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);

    useEffect(() => {
        const fetchTemplates = async () => {
            const res = await ApiClient.get(`/emailtexttemplates?dealId=${dealId}&type=${type}`);
            const templates = res.data.list;

            setTemplates(templates);
            if (templates.length > 0) {
                setFormValues((prev) => ({
                    ...prev,
                    subject: templates[0].subject_filled,
                    message: templates[0].content_filled,
                    selectedTemplate: templates[0],
                }));
            }
            setIsLoadingTemplates(false);
        };

        fetchTemplates();
    }, [dealId, type]);

    const handleTemplateClick = (template: Template) => {
        setFormValues((prev) => ({
            ...prev,
            subject: template.subject_filled,
            message: template.content_filled,
            selectedTemplate: template,
        }));
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { id, value } = e.target;
        setFormValues((prev) => ({ ...prev, [id]: value }));
    };

    const handleSend = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        if (!formValues.selectedTemplate) {
            return;
        }

        setIsLoading(true);
        try {
            const payload = {
                dealId,
                to: formValues.emailAddress,
                subject: formValues.subject,
                body: formValues.message,
                template_id: formValues.selectedTemplate.id,
            };

            await ApiClient.post(`/email`, payload);
            onSubmitSuccess('E-Mail erfolgreich gesendet');
        } catch (error: any) {
            console.error('Error sending email:', error);
            onSubmitSuccess(error.response.data[1] ?? 'Fehler beim Senden der E-Mail', true);
        } finally {
            setIsLoading(false);
            onModalClose();
        }
    };

    return (
        <Modal show onHide={onModalClose} centered size="lg">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoadingTemplates ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <Spinner animation="border" role="status" />
                    </div>
                ) : (
                    <Form noValidate validated={validated} onSubmit={handleSend}>
                        <FormGroup<FormValues>
                            id="emailAddress"
                            label="Empfänger"
                            type="email"
                            value={formValues.emailAddress}
                            onChange={handleInputChange}
                            required
                        />

                        <h6>Vorlagen</h6>
                        <div className="d-flex mb-3 flex-wrap">
                            {templates.map((template) => (
                                <Button
                                    key={template.id}
                                    size="sm"
                                    className={`${formValues.selectedTemplate?.id === template.id
                                        ? 'btn-soft-primary'
                                        : 'btn-outline-neutral'
                                        } me-2 mb-2 rounded-pill`}
                                    onClick={() => handleTemplateClick(template)}
                                >
                                    {template.name}
                                </Button>
                            ))}
                        </div>

                        <FormGroup<FormValues>
                            id="subject"
                            label="Betreff"
                            type="text"
                            value={formValues.subject}
                            onChange={handleInputChange}
                            placeholder="Betreff"
                            required
                        />

                        <FormGroup<FormValues>
                            id="message"
                            label="Nachricht"
                            type="textarea"
                            value={formValues.message}
                            onChange={handleInputChange}
                            placeholder="Nachricht"
                            noResize
                            rows={10}
                            required
                        />

                        <Row className="mt-4 justify-content-end">
                            <Col xs="auto">
                                <Button onClick={onModalClose} className="btn btn-soft-primary me-2">
                                    Abbrechen
                                </Button>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={
                                        isLoading ||
                                        !formValues.subject ||
                                        !formValues.message ||
                                        !formValues.emailAddress
                                    }
                                >
                                    Senden
                                    {isLoading && (
                                        <Spinner
                                            className="ms-2"
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></Spinner>
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default SendMailModal;
