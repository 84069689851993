import React, { useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Spinner,
  Dropdown,
} from 'react-bootstrap';
import FileDropzone from '../FileDropZone';
import { ApiClient } from '../../../services/ApiClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { faPlus, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { Accept } from 'react-dropzone';

type UploadMediaModalProps = {
  modalTitle: string;
  buttonName?: string;
  onSubmitSuccess: (message?: string, isError?: boolean) => void;
  selectedFolderId?: number | null;
  onUploadMediaModalChange: (open: boolean) => void;
  isDropdownItem?: boolean;
  isDisabled?: boolean;
  convention?: string;
  accept?: Accept;
  customUploadEndpoint?: string
  dealId?: number
  isModalOpen?: boolean;
  singleFile?: boolean;
};

/**
 * UploadMediaModal Component
 * 
 * This component provides a modal interface for uploading media files. It supports features like file previews, 
 * drag-and-drop uploads, and validation of file types and sizes. The modal integrates with APIs for uploading files 
 * to specific folders or custom endpoints.
 * 
 * Props:
 * - `modalTitle` (string): The title displayed on the modal.
 * - `buttonName` (string, optional): The name of the button or dropdown item triggering the modal.
 * - `onSubmitSuccess` (function): Callback function triggered on successful upload.
 * - `selectedFolderId` (number | null, optional): The ID of the folder to upload files into.
 * - `onUploadMediaModalChange` (function): Callback function triggered when the modal state changes.
 * - `isDropdownItem` (boolean, optional): Whether the trigger is a dropdown item.
 * - `isDisabled` (boolean, optional): Whether the trigger button/item is disabled.
 * - `convention` (string, optional): Additional convention data to include in the upload payload.
 * - `accept` (Accept, optional): Object defining allowed MIME types and file extensions.
 * - `customUploadEndpoint` (string, optional): Custom API endpoint for uploads.
 * - `dealId` (number, optional): Deal ID to include in the upload payload.
 * - `isModalOpen` (boolean, optional): Controls the modal's open state externally.
 * - `singleFile` (boolean, optional): Restricts uploads to a single file if true (default: false).
 */
const UploadMediaModal: React.FC<UploadMediaModalProps> = ({
  modalTitle,
  buttonName,
  onSubmitSuccess,
  selectedFolderId,
  onUploadMediaModalChange,
  isDropdownItem,
  isDisabled,
  convention,
  accept,
  customUploadEndpoint,
  dealId,
  isModalOpen,
  singleFile = false,
}) => {
  const { folderId = 0 } = useParams();
  const [showModal, setShowModal] = useState(isModalOpen ?? false);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const handleClose = () => {
    onUploadMediaModalChange(false);
    setShowModal(false);
  };

  const handleShow = () => {
    onUploadMediaModalChange(true);
    setShowModal(true);
  };

  const handleFilesChange = (newFiles: File[]) => {
    if (singleFile) {
      setFiles(newFiles.slice(0, 1));
    } else {
      setFiles([...files, ...newFiles]);
    }
  };

  const handleRemoveFile = (fileName: string) => {
    setFiles(files.filter(file => file.name !== fileName));
  };

  const handleUpload = async () => {
    setIsLoading(true);
    try {
      for (let file of files) {
        const formData = new FormData();
        convention && formData.append('convention', convention);
        formData.append('file', file);

        if (customUploadEndpoint && dealId) {
          formData.append('dealId', dealId.toString() ?? '');
          await ApiClient.post(customUploadEndpoint, formData);
        } else {
          formData.append('folder_id', selectedFolderId?.toString() || folderId.toString());
          await ApiClient.post('/upload', formData);
        }
      }
      onSubmitSuccess(`Erfolgreich gespeichert`, false);
      setFiles([]);
    } catch (error) {
      onSubmitSuccess('Fehler beim Speichern', true);
      console.error('Error during file upload:', error);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <>
      {isModalOpen === undefined && (
        <>
          {isDropdownItem ? (
            <Dropdown.Item disabled={isDisabled} onClick={handleShow}>
              {customUploadEndpoint ?
                <FontAwesomeIcon className='text-primary' width={30} icon={faPlus} /> :
                <FontAwesomeIcon width={30} icon={faUpload} />}
              {buttonName}
            </Dropdown.Item>
          ) : (
            <Button
              disabled={isDisabled}
              variant={selectedFolderId?.toString() ? 'btn btn-soft-primary me-4' : 'primary'}
              onClick={handleShow}
            >
              {buttonName}
            </Button>
          )}
        </>
      )}

      <Modal
        centered
        size="lg"
        show={isModalOpen !== undefined ? isModalOpen : showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              <h4>{modalTitle}</h4>
            </Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <FileDropzone multiple={!singleFile} accept={accept} onFilesChange={handleFilesChange} />
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {files.map(file => (
                  <li key={file.name}>
                    <div
                      className="rounded p-2 my-3 text-black d-flex justify-content-between border"
                    >
                      <span>{file.name}</span>
                      <span
                        className="cursor-pointer"
                        onClick={() => handleRemoveFile(file.name)}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn-soft-primary" variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={handleUpload}
            disabled={isLoading || files.length === 0}
          >
            {files?.length} Dateien Hochladen
            {isLoading && (
              <Spinner
                className="ms-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              ></Spinner>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadMediaModal;
